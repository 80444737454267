import React from 'react'
import logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import { Button, InputGroup, Input, Table, Form, Row, Col, FormGroup, Label } from 'reactstrap'
import { FaRegUser } from "react-icons/fa";
import { IoPersonCircle } from "react-icons/io5";

import { IoMdLogIn } from "react-icons/io";
import { MdEmail } from "react-icons/md";




import { FacebookEmbed } from 'react-social-media-embed';
import { IoRocket } from "react-icons/io5";
import mining from '../img/mining.jpg'
import img2 from '../img/img2.jpg'
import img3 from '../img/img3.jpg'
import img4 from '../img/img4.jpg'


import homeimg from '../img/home-banner.gif'
import Footer from './Footer';
import Header from './Header';


function Home() {
    return (
        <div>
            <div className='container pt-3'>

                <Header />
                <div className='row pt-5 pb-5 heght-300'>
                    <div className='col-sm-6'>
                        <div className='mt-5'>
                            <h1 className='main-home text-gradient'>

                                <span className='font-a'>Welcome to </span>

                                <span className='font-b'>Nugget Trap</span>
                            </h1>
                            <div className='pt-5'>
                                <Button className='get-start'> Get Started Now <b>(NGTG$$)</b>
                                    <IoRocket size={20} />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <img src={homeimg} className='img-1' />
                    </div>
                </div>
            </div>
            {/*  */}
            <div className='nses-1 pt-5'>
                <div className='container '>
                    <p>
                        We would like to welcome you to the Nugget Trap gold placer mine project, located in British Columbia,
                        Canada. Our team carefully selected the Nugget Trap Site after an exhaustive search of potential projects
                        that met three important characteristics.
                    </p>
                    <br />
                    <p>
                        First and foremost, our team wanted to identify a project located in a mining friendly Country and territory.
                        Canada itself has historically been actively a pro-mining Country, possessing vast mineral resources, and equally
                        importantly, knowledgeable mine personnel resources to draw upon for extraction. British Columbia’s gold mining history
                        has been nothing short of legendary. With the Nugget Trap Placer Mine sitting inside British Columbia’s famed “Golden
                        Triangle”, we feel the project geographically is in a tier 1 location.
                    </p>
                    <br />
                    <p>
                        The second desired characteristic for this project search was concentration of gold as well as placer shallow access.
                        The Nugget Trap Mine has shown concentrations of gold in the 25 grams/metric ton of pay during the 25 pit assay testing.
                        High concentrations of silver were also present in these various test pits, which is not an uncommon feature of the
                        Golden Triangle area mines. Deep mining is not needed for high concentration recovery on the Nugget Trap Project,
                        making it ideal for success in operational profitability. Samples were taken from surface down to four feet for our
                        testing only. This shallow mining allows for the operational costs to be much lower than deep mining, as well as an
                        expedited timeline for gold recovery.
                    </p>
                    <br />

                    <p>
                        In terms of desirability for gold mining, a proven area of high concentration, which has only been tested and proven but
                        not commercially exploited, is extremely rare. The Nugget Trap Placer Project is one that fits such a description
                    </p>
                    <br />

                </div>
            </div>
            <div className='sec-4 '>
                <div className='dark-gb'>
                    <div className='container pt-3 pb-5'>
                        <h5 className='text-center text-light'>
                            Gold Token Registration: Securing Your Shine in the Future of Digital Wealth!
                        </h5>
                        <div className='pt-3'>
                            <Form>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Title</Label>
                                            <Input/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="exampleEmail">First name</Label>
                                            <Input 
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Last name</Label>
                                            <Input/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Mobile</Label>
                                            <Input 
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Date of Birth</Label>
                                            <Input/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Country</Label>
                                            <Input 
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Have you ever Invested in a Placer Mine Claim Before?</Label>
                                            <Input 
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Amount $</Label>
                                            <Input/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Investment Interest Type</Label>
                                            <Input 
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Investment Method Preference</Label>
                                            <Input 
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Have you ever invested in a Digital Asset Token ?</Label>
                                            <Input 
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="exampleEmail">Message</Label>
                                            <Input 
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                    <p className='disclmr'><b>Disclaimer</b> : We are solely interested in evaluating the Countries of interest from our Pre-registered 
                                        subscribers to best valuate which jurisdictions may be of interest to possibly extend our registration 
                                        for the offering.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                    <Button block color='primary'>Submit</Button>
                                    </Col>
                                </Row>

                            </Form>

                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='bod'>
                <div className='container pt-5 pb-5'>
                    <div className='sec-2'>
                        <h2 className='text-center font-a'>Roadmap and Milestones</h2>
                        <p className='font-a'>Developing a robust tokenomics model is essential for the successful launch of a token associated with
                            <span className='def-col'> Cunningham Mining</span> .</p>

                        <div className='row pt-5'>
                            <div className='col-sm-6 pt-5'>
                                <h2 className='ff'>Phase 1: Exploration and <span className='pmr'>Setup</span> </h2>
                                <p className='text-justify'>Develop infrastructure, conduct geological surveys, and initial exploration of the Nugget Trap property.</p>
                            </div>
                            <div className='col-sm-6 text-end'>
                                <img src={mining} className='img1' />
                            </div>
                        </div>
                        <div className='row pt-5 '>
                            <div className='col-sm-6  pt-5'>
                                <h2 className='ff'>Phase 2: Mining <span className='pmr'>Commencement</span> </h2>
                                <p className='text-justify'>Begin active mining operations, invest in advanced equipment, and optimize production processes.</p>
                            </div>
                            <div className='col-sm-6 text-end'>
                                <img src={img2} className='img1 ' />
                            </div>
                        </div>
                        <div className='row pt-5 '>
                            <div className='col-sm-6  pt-5'>
                                <h2 className='ff'>Phase 3: Expansion and <span className='pmr'>Sustainability</span> </h2>
                                <p className='text-justify'>Expand mining operations to optimize resource extraction, enhance environmental sustainability, and increase profitability.</p>
                            </div>
                            <div className='col-sm-6 text-end'>
                                <img src={img3} className='img1 ' />
                            </div>
                        </div>
                        <div className='row pt-5'>
                            <div className='col-sm-6  pt-5'>
                                <h2 className='ff'>Phase 4: Ecosystem <span className='pmr'>Development</span> </h2>
                                <p className='text-justify'>Introduce partnerships, enhance community engagement, and explore collaborations with other projects in the industry.</p>
                            </div>
                            <div className='col-sm-6 text-end'>
                                <img src={img4} className='img1 ' />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div>

            </div>
            <div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17144.749700800763!2d-122.83542024033999!3d53.9686088156825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53889736534f66a1%3A0x86d6607f6c26c06a!2sPidherny%20Recreation%20Site!5e1!3m2!1sen!2sin!4v1731425006225!5m2!1sen!2sin" width="100%" height="450"
                                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className='col-sm-6'>
                            <h2 className='text-capital ff'>TOKENOMICS <span className='pmr'>MODEL</span> </h2>
                            <div className='text-ara'>
                                <p className='text-justify'>
                                    Developing a robust tokenomics model is essential for the successful launch of a token associated with Cunningham Mining. Tokenomics refers to the economic model governing the issuance, distribution, and utilization of tokens within a specific ecosystem.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sec-4'>
                <div className='container'>
                    <div className='pt-5'>
                        <Table className='info-tbl'>
                            <tbody>
                                <tr>
                                    <th scope="row"> Token Name </th>
                                    <td> NUGGET TRAP (NGTG$$)</td>
                                </tr>
                                <tr>
                                    <th scope="row"> Token Standard </th>
                                    <td> ERC-20 (or suitable standard for your blockchain platform)</td>
                                </tr>
                                <tr>
                                    <th scope="row">Circulating Supply </th>
                                    <td> 1 Billion</td>
                                </tr>
                                <tr>
                                    <th scope="row">Treasury </th>
                                    <td> 4 Billion</td>
                                </tr>
                                <tr>
                                    <th scope="row">Issuance for the offering </th>
                                    <td>100,000,000 NGT Token (One Hundred Million NGTG$$ )</td>
                                </tr>
                                <tr>
                                    <th scope="row">Price of each Digital Token Unit </th>
                                    <td>$0.60 USD</td>
                                </tr>
                                <tr>
                                    <th scope="row">Registration Platform for users </th>
                                    <td>OriginatorX Issuing Platform</td>
                                </tr>
                                <tr>
                                    <th scope="row">Wallets</th>
                                    <td>Metamask Wallet, Smartpay Wallet</td>
                                </tr>
                                <tr>
                                    <th scope="row">Acceptable forms of Payments</th>
                                    <td>Wire, ETH, BTC,USDT</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div> */}

            <Footer />
        </div>
    )
}

export default Home
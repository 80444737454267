import React from 'react'
import logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import { Button, InputGroup, Input, Table, Form, Row, Col, FormGroup, Label } from 'reactstrap'
import { FaRegUser } from "react-icons/fa";
import { IoPersonCircle } from "react-icons/io5";

import { IoMdLogIn } from "react-icons/io";
import { MdEmail } from "react-icons/md";

import { FacebookEmbed } from 'react-social-media-embed';
import { IoRocket } from "react-icons/io5";
import mining from '../img/mining.jpg'
import img2 from '../img/img2.jpg'
import img3 from '../img/img3.jpg'
import isv from '../img/isv.png'


import homeimg from '../img/home-banner.gif'
import Footer from './Footer';
import Header from './Header';


function Ambassador() {
    return (
        <div className='bg-dark1'>
            <div className='container pt-3'>

                <Header />
                <div className='row pt-5 pb-5'>
                    <h4 className='text-center font-a'>Apply For The Program </h4>
                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">First Name</Label>
                            <Input />
                        </FormGroup>
                    </div>
                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">Last Name</Label>
                            <Input />
                        </FormGroup>
                    </div>

                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">Telegram ID </Label>
                            <Input />
                        </FormGroup>
                    </div>
                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">Facebook Link</Label>
                            <Input />
                        </FormGroup>
                    </div>

                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">X (Twitter) ID</Label>
                            <Input />
                        </FormGroup>
                    </div>
                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">Threads Link</Label>
                            <Input />
                        </FormGroup>
                    </div>

                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">Instagram ID</Label>
                            <Input />
                        </FormGroup>
                    </div>
                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">LinkedIn Link</Label>
                            <Input />
                        </FormGroup>
                    </div>
                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">Youtube</Label>
                            <Input />
                        </FormGroup>
                    </div>
                    <div className='col-sm-6'>
                        <FormGroup>
                            <Label for="exampleEmail">Email</Label>
                            <Input />
                        </FormGroup>
                    </div>
                    <div className='col-sm-12'>
                        <FormGroup>
                            <Label for="exampleEmail">Why do you want to join the Program?</Label>
                            <Input />
                        </FormGroup>
                    </div>
                    <div className='col-sm-12'>
                        <FormGroup>
                            <Button block color='primary'>Submit</Button>
                        </FormGroup>
                    </div>

                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Ambassador
import React from 'react'
import logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import { Button, InputGroup, Input, Table, Form, Row, Col, FormGroup, Label } from 'reactstrap'
import { FaRegUser } from "react-icons/fa";
import { IoPersonCircle } from "react-icons/io5";

import { IoMdLogIn } from "react-icons/io";
import { MdEmail } from "react-icons/md";

import { FacebookEmbed } from 'react-social-media-embed';
import { IoRocket } from "react-icons/io5";
import mining from '../img/mining.jpg'
import img2 from '../img/img2.jpg'
import img3 from '../img/img3.jpg'
import isv from '../img/isv.png'


import homeimg from '../img/home-banner.gif'
import Footer from './Footer';
import Header from './Header';


function Location() {
    return (
        <div>
            <div className='container pt-3'>

                <Header />
                <div className='row pt-5 pb-5 '>
                    <div className='col-sm-12'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17144.749700800763!2d-122.83542024033999!3d53.9686088156825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53889736534f66a1%3A0x86d6607f6c26c06a!2sPidherny%20Recreation%20Site!5e1!3m2!1sen!2sin!4v1731425006225!5m2!1sen!2sin" width="100%" height="450"
                                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    )
}


export default Location
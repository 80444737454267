import React from 'react'
import fb from '../img/social/fb.png';
import twiter from '../img/social/twr.png';
import instra from '../img/social/ins.png'
import linkdin from '../img/social/link.png';
import tl from '../img/social/t.png';
import youtube from '../img/social/yout.png';
import tel from '../img/social/tel.png'

import logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import { Button, InputGroup, Input, Table } from 'reactstrap'
import { FaRegUser } from "react-icons/fa";
import { IoPersonCircle } from "react-icons/io5";

import { IoMdLogIn } from "react-icons/io";
import { MdEmail } from "react-icons/md";

import { FacebookEmbed } from 'react-social-media-embed';


function Footer() {
    return (
        <div>
            <div className='footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='pt-5'>
                                <img src={logo} className='footer-logo' />
                                <p className='text-light desc-text'>We would like to welcome you to the Nugget Trap gold placer mine project</p>
                                <div className='subs-form'>
                                    <InputGroup>
                                        <Input />
                                        <Button className='subs-btn'>
                                            <MdEmail /> Subscribe
                                        </Button>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-3 pt-5 footer-menu'>
                            <h5 className='text-light'>Quick links</h5>
                            <ul>
                                <li><Link>Home</Link> </li>
                                <li><Link>Assay Report</Link> </li>
                                <li><Link>Investors</Link> </li>
                                <li><Link>Location</Link> </li>
                                <li><Link>Digital Assets Tokenomics</Link> </li>
                                <li><Link>CEX</Link> </li>
                            </ul>
                        </div>
                        <div className='col-sm-3 pt-5 '>
                            <h5 className='text-light'>Folling Us</h5>
                            <ul className='social-logo'>
                                <li><Link> <img src={fb} alt='' /> </Link> </li>
                                <li><Link> <img src={twiter} alt='' /> </Link> </li>
                                <li><Link> <img src={instra} alt='' /> </Link> </li>
                                <li><Link> <img src={linkdin} alt='' /> </Link> </li>
                                <li><Link> <img src={tl} alt='' /> </Link> </li>
                                <li><Link> <img src={youtube} alt='' /> </Link> </li>
                                <li><Link> <img src={tel} alt='' /> </Link> </li>
                            </ul>
                            <div className='fb-page'>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <FacebookEmbed url="https://www.facebook.com/61552312260533/videos/1222033735580216" width={300} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copy-right'>
                <p className='copy-text'>© {new Date().getFullYear()} <Link to="https://nuggettrap.com/">Nuggettrap</Link> . All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer
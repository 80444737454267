import React from 'react'
import { Button, InputGroup, Input, Table, Form, Row, Col, FormGroup, Label } from 'reactstrap'

import { Link } from 'react-router-dom';

import img1 from '../img/im1.png';
import img2 from '../img/im2.png';
import img3 from '../img/im3.png';
import img4 from '../img/im4.png';

import imga11 from '../img/img11.png';
import img12 from '../img/img12.png';
import img13 from '../img/img13.png';
import img14 from '../img/img14.png';

import logo from '../img/logo.png'


import homeimg from '../img/home-banner.gif'
import Footer from './Footer';
import Header from './Header';


function Cex() {
    return (
        <div className='bg-dark'>
            <div className='container pt-3'>
                <Header />
                <div className='row main-img pt-5 pb-5 cex-img'>
                    <div className='col-sm-6'>
                        <img src={img1} alt='' className='img-fluid' />
                        <p className='text-right'><Link
                            to='https://www.biconomy.com/exchange/NGTG$$_USDT'>
                            https://www.biconomy.com/exchange/NGTG$$_USDT
                        </Link>
                        </p>
                    </div>
                    <div className='col-sm-6 text-end'>
                        <img src={img2} alt='' className='img-fluid' />
                        <p className='text-right'><Link
                            to='https://www.xt.com/en/trade/ngtg_usdt'>
                            https://www.xt.com/en/trade/ngtg_usdt
                        </Link>
                        </p>
                    </div>
                    <div className='col-sm-6'>
                        <img src={img3} alt='' className='img-fluid' />
                        <p className='text-right'><Link
                            to='https://dex-trade.com/spot/trading/NGTG$$USDT?interface=classic'>
                            https://dex-trade.com/spot/trading/NGTG$$USDT?interface=classic
                        </Link>
                        </p>
                    </div>
                    <div className='col-sm-6 text-end'>
                        <img src={img4} alt='' className='img-fluid' />
                        <p className='text-right'><Link
                            to='https://uzx.com/#/exchange/ngtg_usdt'>
                            https://uzx.com/#/exchange/ngtg_usdt
                        </Link>
                        </p>
                    </div>
                </div>
                {/* <div className='row pt-5 pb-5 cex-img'>
                    <div className='col-sm-4'>
                        <img src={logo} alt='' />
                    </div>
                    <div className='col-sm-4 text-center'>
                        <h4 className='text-white text-center'>COMING SOON </h4>
                    </div>
                    <div className='col-sm-4 text-end'>
                        <h4 className='text-white'>#RWATOKEN</h4>
                    </div>
                </div> */}
                <div className='row pt-5 pb-5  cex-img'>
                    <div className='col-sm-6'>
                        <img src={imga11} alt='' />
                    </div>
                    <div className='col-sm-6 text-end'>
                        <img src={img12} alt='' />
                    </div>
                    <div className='col-sm-6'>
                        <img src={img13} alt='' />
                    </div>
                    <div className='col-sm-6 text-end'>
                        <img src={img14} alt='' />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}


export default Cex
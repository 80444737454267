import React from 'react'
import logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import { Button, InputGroup, Input, Table, Form, Row, Col, FormGroup, Label } from 'reactstrap'
import { FaRegUser } from "react-icons/fa";
import { IoPersonCircle } from "react-icons/io5";

import { IoMdLogIn } from "react-icons/io";
import { MdEmail } from "react-icons/md";

import { FacebookEmbed } from 'react-social-media-embed';
import { IoRocket } from "react-icons/io5";
import mining from '../img/mining.jpg'
import img2 from '../img/img2.jpg'
import img3 from '../img/img3.jpg'
import isv from '../img/isv.png'


import homeimg from '../img/home-banner.gif'
import Footer from './Footer';
import Header from './Header';


function Investor() {
    return (
        <div>
            <div className='container pt-3'>

                <Header />
                <div className='row pt-5 pb-5 '>
                    <div className='col-sm-12'>
                        <img src={isv}  style={{width:'100%'}}/>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    )
}


export default Investor
import React from 'react'

import logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import { Button, InputGroup, Input, Table } from 'reactstrap'
import { FaRegUser } from "react-icons/fa";
import { IoPersonCircle } from "react-icons/io5";

import { IoMdLogIn } from "react-icons/io";
import { MdEmail } from "react-icons/md";

function Header() {
    return (
        <div>
            <div className='navbar-main '>
                <div>
                    <img src={logo} className='logo-nav' />
                </div>
                <div className='pt-2'>
                    <ul>
                        <li><Link to='/'>Home</Link> </li>
                        <li><Link to='https://drive.google.com/file/d/1oGSZOqPsmHZ9Vh2EXSCiu4hX-p3seUEV/view' target='_blank'> Assay Report</Link> </li>
                        <li><Link to='/investor'>Investors</Link> </li>
                        <li><Link to='/location'>Location</Link> </li>
                        <li><Link to='/tokenomics'>Digital Assets Tokenomics</Link> </li>
                        <li><Link to='/cex'>CEX
                        </Link> </li>
                        <li><Link to='/community'>Community</Link> </li>
                        <li><Link to='/ambassador'>Ambassador Program</Link> </li>
                    </ul>
                </div>
                {/* <div className='nav-btnn pt-1'>
                    <Button size="sm" className='cus-button'>
                        <IoPersonCircle size={20} />
                        Login</Button>
                    <Button size="sm" className='cus-button'>
                        <IoMdLogIn size={20} />
                        Registration</Button>
                </div> */}
            </div>
        </div>
    )
}

export default Header
import logo from './logo.svg';
import './App.css';
import { Button } from 'reactstrap';
import { Link, Route, Routes } from 'react-router-dom';
import Home from './component/Home';
import About from './component/About';
import Investor from './component/Investor';
import Location from './component/Location';
import Tokenomics from './component/Tokenomics';
import Cex from './component/Cex';
import Community from './component/Community';
import Ambassador from './component/Ambassador';


function App() {
  return (
    <div className='main-body'>
      <Routes>
        <Route path='/' element={<Home /> } ></Route>
        <Route path='/about' element={<About /> } ></Route>
        <Route path='/investor' element={<Investor /> } ></Route>
        <Route path='/location' element={<Location /> } ></Route>
        <Route path='/tokenomics' element={<Tokenomics /> } ></Route>
        <Route path='/cex' element={<Cex /> } ></Route>
        <Route path='/community' element={<Community /> } ></Route>
        <Route path='/ambassador' element={<Ambassador /> } ></Route>
      </Routes>

    </div>
  );
}

export default App;
